import { Header } from "semantic-ui-react";
import {
  seller_address,
  seller_bank,
  seller_docs,
  seller_url,
} from "../../../api/urls";
import BackButton from "../../../components/BackButton/BackButton";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../utils/useFetch";
import { TitleLine } from "../../../components/lineTitle";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import Loading from "../../../components/Loading/Loading";
import { useModal } from "../../../components/Modaler";
import { useState } from "react";
import { api } from "../../../api/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import moment from "moment";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import { sellerAddressFormatter } from "../../../utils/addresssFormatter";
import {
  EditorInput,
  editorToText,
  textToEditor,
} from "../../../components/EditorInput/editorInput";
import { serialize } from "../../../utils/serialize";

const backUrl = "/sellers/profile_kyc";
export function VerifySellerProfile() {
  const title = "Seller Verification";
  const apiUrl = seller_url;
  const params = useParams();
  const id = params.id;
  const history = useHistory();
  const toast = useToast();

  const data = useFetch(apiUrl + id + "/");
  const documentData = useFetch(
    seller_docs + "?seller=" + data?.data?.id,
    null,
    !data.loading
  );

  const [saving, setSaving] = useState(false);

  if (data.loading || !data.data) {
    return <Loading />;
  }

  return (
    <div>
      <BackButton href={backUrl} />
      <Header>{title}</Header>
      <div className="p-10" />
      <PersonalInfo data={data} />
      <div className="p-10" />
      <BankInfo data={data} />
      <div className="p-10" />
      <AddressInfo data={data} />
      <div className="p-10" />
      <UploadedDocuments data={data} documentData={documentData} />
      <div className="p-10" />
      <div className="p-10" />
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <div style={{ flex: 1 }} />
        <button
          className="btn btn-red"
          disabled={
            saving ||
            documentData?.data?.some(
              (d) => d?.document_request_status === "IN PROCESS"
            )
          }
          onClick={async () => {
            setSaving(true);
            try {
              await api.patch(seller_url + id + "/", {
                seller_request_status: "REJECTED",
                is_verified: false,
              });
              history.push(backUrl);
              toast.open("Application rejected", "success");
            } catch (e) {
              if (e?.response?.status === 400 && e?.response?.data?.data) {
                toast.open(e?.response?.data.data, "success");
              } else {
                unknownError();
              }
            }
            setSaving(false);
          }}
        >
          Reject
        </button>
        <button
          className="btn"
          disabled={
            saving ||
            documentData?.data?.some(
              (d) => d?.document_request_status !== "APPROVED"
            )
          }
          onClick={async () => {
            setSaving(true);
            try {
              await api.patch(seller_url + id + "/", {
                seller_request_status: "APPROVED",
                is_verified: true,
              });
              history.push(backUrl);
              toast.open("Application accepted", "success");
            } catch (e) {
              if (e?.response?.status === 400 && e?.response?.data?.data) {
                toast.open(e?.response?.data.data, "success");
              } else {
                unknownError();
              }
            }
            setSaving(false);
          }}
        >
          Accept
        </button>
      </div>
      <div className="p-10" />
    </div>
  );
}

function UploadedDocuments({ data, documentData }) {
  const moduler = useModal();
  return (
    <>
      <TitleLine right>Uploaded documents</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Document name",
            field: "doc_name",
          },
          {
            headerName: "Document number",
            field: "doc_number",
          },
          {
            headerName: "Document expiry",
            field: "doc_expiry",
            renderCell: (params) =>
              params.value ? moment(params.value).format("DD-MM-YYYY") : "-",
          },
          {
            headerName: "File type",
            field: "doc_file",
            renderCell: (params) => params?.value?.split(".").pop(),
          },
          {
            headerName: "Status",
            field: "document_request_status",
          },
          {
            headerName: "Action",
            field: "doc_file",
            renderCell: (params) => (
              <div
                className="blue-link"
                onClick={() => {
                  moduler.opener(
                    <DocumentVerifyModal
                      data={params.row}
                      onSave={() => {
                        moduler.closer();
                        data.refetch();
                      }}
                    />,
                    true
                  );
                }}
              >
                View
              </div>
            ),
          },
        ]}
        rows={documentData.data}
        loading={documentData.loading}
      />
    </>
  );
}

function DocumentVerifyModal({ data, onSave }) {
  function checkURLIsImage(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  const [rejected, setRejected] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [comment, setComment] = useState(textToEditor(""));

  return (
    <div
      style={{
        padding: "20px",
        width: "700px",
        maxHeight: "90vh",
        maxWidth: "90vw",
        overflow: "auto",
      }}
    >
      <div style={{ height: "300px" }}>
        {data.doc_file ? (
          checkURLIsImage(data.doc_file) ? (
            <DashboardImage src={data.doc_file} width="500px" height="300px" />
          ) : (
            <object data={data.doc_file} width="500px" height="300px">
              <p>
                Unable to display this file, click
                <a href={data.doc_file} target="_blank" rel="noreferrer">
                  {" here "}
                </a>
                to open it.
              </p>
            </object>
          )
        ) : (
          <p>No document file available</p>
        )}
      </div>
      <div className="p-10" />
      <div className="p-10" />
      {rejected ? (
        <>
          <EditorInput
            value={comment}
            onChange={(v) => setComment(v)}
            label="Comment"
          />
          <div className="p-10" />
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn"
              disabled={isSaving}
              onClick={async () => {
                setIsSaving(true);
                try {
                  await api.patch(
                    seller_docs + data.id + "/",
                    serialize({
                      is_verified: false,
                      reject_comment: editorToText(comment),
                      document_request_status: "REJECTED",
                    })
                  );
                  onSave();
                } catch (e) {
                  unknownError();
                }
                setIsSaving(false);
              }}
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        data.document_request_status !== "APPROVED" && (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <button className="btn btn-red" onClick={() => setRejected(true)}>
              Reject
            </button>
            <button
              className="btn"
              disabled={isSaving}
              onClick={async () => {
                setIsSaving(true);
                try {
                  await api.patch(
                    seller_docs + data.id + "/",
                    serialize({
                      is_verified: true,
                      document_request_status: "APPROVED",
                    })
                  );
                  onSave();
                } catch (e) {
                  unknownError();
                }
                setIsSaving(false);
              }}
            >
              Accept
            </button>
          </div>
        )
      )}
    </div>
  );
}

function PersonalInfo({ data }) {
  return (
    <>
      <TitleLine right>Personal Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Image",
            renderCell: (params) => (
              <DashboardImage
                width="50px"
                height="50px"
                src={params?.row?.company_image}
                circle
              />
            ),
          },
          {
            headerName: "Name",
            renderCell: (params) =>
              params.row.firstname
                ? `${params?.row?.firstname} ${params?.row?.lastname}`
                : "-",
          },
          {
            headerName: "Company Name",
            field: "company_name",
          },
          {
            headerName: "Email ID",
            field: "email_id",
          },
          {
            headerName: "Website",
            field: "website",
          },
          {
            headerName: "Digital Signature",
            renderCell: (params) => (
              <DashboardImage
                width="50px"
                height="50px"
                src={params?.row?.digital_signature}
              />
            ),
          },
        ]}
        rows={[{ ...data?.data }]}
        loading={data.loading}
      />
    </>
  );
}

function BankInfo({ data }) {
  const bankInfo = useFetch(seller_bank + "?seller=" + data.data.id);
  return (
    <>
      <TitleLine right>Bank Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Account Holder Name",
            field: "account_name",
          },
          {
            headerName: "Bank Name",
            field: "bank_name",
          },
          {
            headerName: "Account Number",
            field: "account_number",
          },
          {
            headerName: "IFSC Code",
            field: "ifsc_code",
          },
        ]}
        rows={bankInfo.data}
        loading={bankInfo.loading}
      />
    </>
  );
}

function AddressInfo({ data }) {
  const addressInfo = useFetch(seller_address + "?seller=" + data.data.id);
  return (
    <>
      <TitleLine right>Address Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Address",
            renderCell: (params) => sellerAddressFormatter(params.row),
          },
          { headerName: "Contact Name", field: "contact_name" },
          { headerName: "Contact Number", field: "contact_number" },
          { headerName: "Pincode", field: "pincode" },
        ]}
        rows={addressInfo.data}
        loading={addressInfo.loading}
      />
    </>
  );
}

import { Header } from "semantic-ui-react";
import BackButton from "../../../components/BackButton/BackButton";
import { useState } from "react";
import Tabs from "../../../components/Tabs/Tabs";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import moment from "moment";
import { TextChip } from "../../../components/TextChip/TextChip";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import { useFetchWithPagination } from "../../../utils/useFetchWithPagination";
import {
  bproduct_add_url,
  coupons_url,
  gproduct_add_url,
  product_add_url,
  reels_url,
  seller_brand_documents_url,
  seller_brand_url,
  seller_category_documents_url,
  seller_category_url,
} from "../../../api/urls";
import { useFetch } from "../../../utils/useFetch";
import Loading from "../../../components/Loading/Loading";
import { na, na2 } from "../../../utils/na";
import { useModal } from "../../../components/Modaler";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import { useParamsState } from "../../../utils/useParamsState";
import {
  EditorInput,
  editorToText,
  textToEditor,
} from "../../../components/EditorInput/editorInput";
import { TitleLine } from "../../../components/lineTitle";

const tabs = [
  {
    title: "Brand approval",
    code: "brand",
    api: `${seller_brand_url}`,
    singelApi: `${seller_brand_url}`,
    documentsApi: `${seller_brand_documents_url}`,
    statusKey: "brand_request_status",
  },
  {
    title: "Category approval",
    code: "category",
    api: `${seller_category_url}`,
    singelApi: `${seller_category_url}`,
    documentsApi: `${seller_category_documents_url}`,
    statusKey: "category_request_status",
  },
  {
    title: "Local Product approval",
    code: "local_product",
    api: `${product_add_url}`,
    singelApi: `${product_add_url}`,
    statusKey: "product_request_status",
  },
  {
    title: "Global Product approval",
    code: "global_product",
    api: `${gproduct_add_url}`,
    singelApi: `${product_add_url}`,
    statusKey: "product_request_status",
  },
  {
    title: "B2B Product approval",
    code: "b2b_product",
    api: `${bproduct_add_url}`,
    singelApi: `${product_add_url}`,
    statusKey: "product_request_status",
  },
  {
    title: "Reels approval",
    code: "reel",
    api: `${reels_url}`,
    singelApi: `${reels_url}`,
    statusKey: "reels_request_status",
  },
  // {
  //   title: "Profile image approval",
  //   code: "image",
  //   api: `${delivery_boy_temp_url}`,
  //   singelApi: `${delivery_boy_temp_url}`,
  // },
  {
    title: "Coupon approval",
    code: "coupon",
    api: `${coupons_url}`,
    singelApi: `${coupons_url}`,
    statusKey: "coupon_request_status",
  },
];

const module = modules.OtherKYC;

export function OtherKYC() {
  const title = "Other KYC";
  const currentRoute = "other_kyc";

  const [code, setCode] = useParamsState("code", tabs[0].code);
  const [activeTab, setActiveTab] = useState(
    tabs.findIndex((tab) => tab.code === code)
  );
  const { data, pagination } = useFetchWithPagination(tabs[activeTab].api);

  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "10px",
          flexShrink: 0,
          overflow: "auto",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            flexShrink: 0,
          }}
        >
          <Tabs
            options={tabs}
            active={activeTab}
            setActive={(index) => {
              setCode(tabs[index].code);
              setActiveTab(index);
            }}
          />
        </div>
      </div>
      <div className="p-5" />
      <TableComponent
        columns={columnsByCode(
          tabs[activeTab].code,
          tabs[activeTab].statusKey,
          currentRoute
        )}
        rows={data.data?.results || []}
        loading={data.loading}
      />
      {pagination}
    </>
  );
}

function columnsByCode(code, statusKey, currentRoute) {
  return [
    ...columnsByCodeWithoutDefault(code, statusKey, currentRoute),
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => (
        <ActionDropdown
          options={(closer) => {
            return (
              <>
                {(params?.row?.[statusKey] === "IN PROCESS" ||
                  params?.row?.[statusKey] === "IN QC") && (
                  <ProtectedElement
                    module={module}
                    perm={perms.verifySellerOtherKYC}
                  >
                    <Link
                      to={currentRoute + `/verify/${code}/${params.row.id}`}
                    >
                      <div
                        className="option"
                        onClick={() => {
                          closer();
                        }}
                      >
                        {/* <div className="icon-btn purple-icon">
                      <UserIcon />
                    </div> */}
                        <div className="purple-title">Verify</div>
                      </div>
                    </Link>
                  </ProtectedElement>
                )}
              </>
            );
          }}
        />
      ),
    },
  ];
}

function columnsByCodeWithoutDefault(code, statusKey, currentRoute) {
  if (code === "coupon") {
    return [
      {
        headerName: "Promo code",
        field: "promocode",
      },
      {
        headerName: "Discount",
        field: "discount_type",
        renderCell: (params) =>
          `${
            params?.row?.discount_type === "value"
              ? `${params?.row?.currency_symbol}`
              : ""
          }${params?.row?.discount_upto}${
            params?.row?.discount_type === "percent" ? `%` : ""
          }`,
      },
      {
        headerName: "Coupon expiry",
        field: "expiry",
        renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
      },
      {
        headerName: "Update date",
        field: "updated_at",
        renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
      },
    ];
  }

  if (code === "brand") {
    return [
      {
        headerName: "Name",
        renderCell: (params) => `${params?.row?.brands_name}`,
      },
      {
        headerName: "Upload date",
        field: "created_at",
        flex: 1,
        renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
      },
      {
        headerName: "Verified ?",
        field: statusKey,
        flex: 1,
        renderCell: (params) => (
          <TextChip text={params.value} color="#FF6F31" />
        ),
      },
    ];
  }

  if (code === "category") {
    return [
      {
        headerName: "category",
        renderCell: (params) =>
          `${params?.row?.category_first_name} > ${params?.row?.category_second_name} > ${params?.row?.category_third_name}`,
      },
      {
        headerName: "Upload date",
        field: "created_at",
        flex: 1,
        renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
      },
      {
        headerName: "Verified ?",
        field: statusKey,
        flex: 1,
        renderCell: (params) => (
          <TextChip text={params.value} color="#FF6F31" />
        ),
      },
    ];
  }

  if (
    code === "local_product" ||
    code === "global_product" ||
    code === "b2b_product"
  ) {
    return [
      {
        headerName: "Image",
        renderCell: (params) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <DashboardImage
              src={params?.row?.product_inventory?.[0]?.image01}
              height="75px"
              width="75px"
            />
          </div>
        ),
      },
      {
        headerName: "Title",
        field: "title",
      },
      {
        headerName: "Variants",
        field: "product_inventory",
        renderCell: (params) =>
          params?.row?.product_inventory?.filter((x) => x.is_active)?.length,
      },
      {
        headerName: "Upload date",
        field: "created_at",
        flex: 1,
        renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
      },
      {
        headerName: "Verified ?",
        field: statusKey,
        flex: 1,
        renderCell: (params) => (
          <TextChip text={params.value} color="#FF6F31" />
        ),
      },
    ];
  }

  if (code === "reel") {
    return [
      {
        headerName: "Reel",
        field: "reels_video",
        flex: 1,
        renderCell: (params) => (
          <video width="150" height="150" controls>
            <source src={params.value} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ),
      },
      {
        headerName: "Title",
        field: "title",
      },
      {
        headerName: "Seller",
        field: "seller_video",
      },
      {
        headerName: "Upload date",
        field: "created_at",
        flex: 1,
        renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
      },
      {
        headerName: "Verified ?",
        field: statusKey,
        flex: 1,
        renderCell: (params) => (
          <TextChip text={params.value} color="#FF6F31" />
        ),
      },
    ];
  }

  // default
  return [
    {
      headerName: "Image",
      field: "form_data.photo",
      flex: 1,
      renderCell: (params) => (
        <DashboardImage width="50px" src={params?.row?.form_data?.photo} />
      ),
    },
    {
      headerName: "Name",
      field: "form_data.first_name",
      flex: 1,
      renderCell: (params) =>
        `${params?.row?.form_data?.first_name} ${params?.row?.form_data?.last_name}`,
    },
  ];
}

export function OtherKYCVerification() {
  const params = useParams();
  const id = params.id;
  const code = params.code;
  const backUrl = `/sellers/other_kyc?code=${params.code}`;
  const activeTab = tabs.find((tab) => tab.code === code);
  const title = activeTab.title;
  const modalar = useModal();
  const toast = useToast();
  const history = useHistory();

  const data = useFetch(activeTab.singelApi + id + "/");

  const [saving, setSaving] = useState(false);

  if (data.loading || !data.data) {
    return <Loading />;
  }

  return (
    <>
      <BackButton href={backUrl} />
      <Header>{title}</Header>
      <div className="p-10" />
      <ExtraKeys code={code} data={data.data} />
      <div className="p-10" />
      {activeTab.documentsApi && (
        <>
          <UploadedDocuments
            data={data}
            apiUrl={activeTab.documentsApi}
            code={code}
          />
          <div className="p-10" />
        </>
      )}
      <div className="p-10" />
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <button
          className="btn-red"
          disabled={
            saving ||
            data?.data.docs?.some(
              (d) => d?.document_request_status === "IN PROCESS"
            )
          }
          onClick={() => {
            modalar.opener(
              <RejectModal params={params} history={history} />,
              true
            );
          }}
        >
          Reject
        </button>
        <button
          className="btn"
          disabled={
            saving ||
            data?.data.docs?.some(
              (d) => d?.document_request_status !== "APPROVED"
            )
          }
          onClick={async () => {
            setSaving(true);
            try {
              await api.patch(activeTab.singelApi + id + "/", {
                [activeTab.statusKey]:
                  code === "local_product" ||
                  code === "global_product" ||
                  code === "b2b_product"
                    ? "QC PASSED"
                    : "APPROVED",
              });
              toast.open("Success", "success");
              history.push(backUrl);
            } catch (e) {
              if (e?.response?.status === 400 && e?.response?.data?.data) {
                toast.open(e?.response?.data.data, "success");
              } else {
                unknownError();
              }
            }
            setSaving(false);
          }}
        >
          Approve
        </button>
      </div>
      <div className="p-10" />
    </>
  );
}

function UploadedDocuments({ data, code, apiUrl }) {
  const moduler = useModal();
  return (
    <>
      <TitleLine right>Uploaded documents</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Document name",
            field: "doc_name",
          },
          {
            headerName: "Doc no.",
            field: "doc_number",
          },
          {
            headerName: "Document expiry",
            field: "doc_expiry",
            renderCell: (params) =>
              params.value ? moment(params.value).format("DD-MM-YYYY") : "-",
          },
          {
            headerName: "File type",
            field: "doc_file",
            renderCell: (params) => params?.value?.split(".").pop(),
          },
          {
            headerName: "Status",
            field: "document_request_status",
          },
          {
            headerName: "Action",
            field: "doc_file",
            renderCell: (params) => (
              <div
                className="blue-link"
                onClick={() => {
                  moduler.opener(
                    <DocumentVerifyModal
                      data={params.row}
                      apiUrl={apiUrl}
                      code={code}
                      onSave={() => {
                        moduler.closer();
                        data.refetch();
                      }}
                    />,
                    true
                  );
                }}
              >
                View
              </div>
            ),
          },
        ]}
        rows={data.data.docs}
        loading={data.loading}
      />
    </>
  );
}

function DocumentVerifyModal({ data, onSave, apiUrl }) {
  function checkURLIsImage(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  const [rejected, setRejected] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [comment, setComment] = useState(textToEditor(""));

  return (
    <div
      style={{
        padding: "20px",
        width: "700px",
        maxHeight: "90vh",
        maxWidth: "90vw",
        overflow: "auto",
      }}
    >
      <div style={{ height: "300px" }}>
        {data.doc_file ? (
          checkURLIsImage(data.doc_file) ? (
            <DashboardImage src={data.doc_file} width="500px" height="300px" />
          ) : (
            <object data={data.doc_file} width="500px" height="300px">
              <p>
                Unable to display this file, click
                <a href={data.doc_file} target="_blank" rel="noreferrer">
                  {" here "}
                </a>
                to open it.
              </p>
            </object>
          )
        ) : (
          <p>No document file available</p>
        )}
      </div>
      <div className="p-10" />
      <div className="p-10" />
      {rejected ? (
        <>
          <EditorInput
            value={comment}
            onChange={(v) => setComment(v)}
            label="Comment"
          />
          <div className="p-10" />
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn"
              disabled={isSaving}
              onClick={async () => {
                setIsSaving(true);
                try {
                  await api.patch(apiUrl + data.id + "/", {
                    is_verified: false,
                    reject_comment: editorToText(comment),
                    document_request_status: "REJECTED",
                  });
                  onSave();
                } catch (e) {
                  unknownError();
                }
                setIsSaving(false);
              }}
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        data.doc_status !== "Approved" && (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <button className="btn btn-red" onClick={() => setRejected(true)}>
              Reject
            </button>
            <button
              className="btn"
              disabled={isSaving}
              onClick={async () => {
                setIsSaving(true);
                try {
                  await api.patch(apiUrl + data.id + "/", {
                    is_verified: true,
                    document_request_status: "APPROVED",
                  });
                  onSave();
                } catch (e) {
                  unknownError();
                }
                setIsSaving(false);
              }}
            >
              Accept
            </button>
          </div>
        )
      )}
    </div>
  );
}

function RejectModal({ params, history }) {
  const [comment, setComment] = useState(textToEditor(""));
  const [saving, setSaving] = useState(false);
  const modalar = useModal();
  const toast = useToast();
  const code = params.code;
  const backUrl = `/sellers/other_kyc?code=${params.code}`;
  const activeTab = tabs.find((tab) => tab.code === code);
  const id = params.id;
  return (
    <div
      style={{
        padding: "20px",
        width: "500px",
        maxWidth: "90vw",
        maxHeight: "90vh",
        overflow: "auto",
      }}
    >
      <EditorInput
        value={comment}
        onChange={(v) => setComment(v)}
        label="Comment"
      />
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }} />
        <button
          className="btn-red"
          disabled={saving}
          onClick={async () => {
            setSaving(true);
            try {
              await api.patch(activeTab.singelApi + id + "/", {
                [activeTab.statusKey]:
                  code === "local_product" ||
                  code === "global_product" ||
                  code === "b2b_product"
                    ? "QC FAILED"
                    : "REJECTED",
                comments: editorToText(comment),
              });
              modalar.closer();
              toast.open("Success", "success");
              history.push(backUrl);
            } catch (e) {
              if (e?.response?.status === 400 && e?.response?.data?.data) {
                toast.open(e?.response?.data.data, "success");
              } else {
                unknownError(e);
              }
            }
            setSaving(false);
          }}
        >
          Reject
        </button>
      </div>
    </div>
  );
}

function ExtraKeys({ code, data: d }) {
  if (code === "coupon") {
    return (
      <div className="flex-column" style={{ gap: "10px" }}>
        <div>
          <b>Promo code : </b>
          {na(d?.promocode)}
        </div>
        <div>
          <b>Discount : </b>
          {na(
            `${d?.discount_type === "value" ? `${d?.currency_symbol}` : ""}${
              d?.discount_upto
            }${d?.discount_type === "percent" ? `%` : ""}`
          )}
        </div>
        <div>
          <b>Coupon expiry : </b>
          {na(moment(d?.expiry).format("DD/MM/YYYY"))}
        </div>
        <Link to={`/coupons/view/${d.id}`} target="_blank" rel="noreferrer">
          <div className="blue-link">View full data</div>
        </Link>
      </div>
    );
  }
  if (
    code === "local_product" ||
    code === "global_product" ||
    code === "b2b_product"
  ) {
    return (
      <div className="flex-column" style={{ gap: "10px" }}>
        <div>
          <b>Image : </b>
          <div>
            <DashboardImage
              src={d?.product_inventory?.[0]?.image01}
              height="75px"
              width="75px"
            />
          </div>
        </div>
        <div>
          <div>
            <b>Title : </b>
            {na(d.title)}
          </div>
        </div>
        <div>
          <div>
            <b>Description : </b>
            {na(d.description)}
          </div>
        </div>
        <div>
          <div>
            <b>Variants : </b>
            {na(d.product_inventory?.filter((x) => x.is_active)?.length)}
          </div>
        </div>
        <Link
          to={`${
            code === "local_product"
              ? "/products/view/"
              : code === "global_product"
              ? "/global_store/products/view/"
              : "/b2b_store/products/view/"
          }${d.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="blue-link">View full data</div>
        </Link>
      </div>
    );
  }
  if (code === "category") {
    return (
      <div className="flex-column" style={{ gap: "10px" }}>
        <div>
          <b>category : </b>
          {na(d?.category_first_name)} {">"} {na(d?.category_second_name)}
          {">"} {na(d?.category_third_name)}
        </div>
        <div>
          <b>category one id : </b>
          {na(d?.category_first)}
        </div>
        <div>
          <b>category two id : </b>
          {na(d?.category_second)}
        </div>
        <div>
          <b>category three id : </b>
          {na(d?.category_third)}
        </div>
      </div>
    );
  }
  if (code === "brand") {
    return (
      <div className="flex-column" style={{ gap: "10px" }}>
        <div>
          <b>Name : </b>
          {na(d?.brands_name)}
        </div>
        <div>
          <b>Brand ID : </b>
          {na(d?.brand)}
        </div>
        <Link
          to={`/masters/brands/view/${d.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="blue-link">View full data</div>
        </Link>
      </div>
    );
  }
  if (code === "reel") {
    return (
      <div className="flex-column" style={{ gap: "10px" }}>
        <div>
          <b>title : </b>
          {na(d?.title)}
        </div>
        <div>
          <b>description : </b>
          {na(d?.description)}
        </div>
        <div>
          <b>video : </b>
          {na2(
            d?.reels_video,
            <video width="150" height="150" controls>
              <source src={d.reels_video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        <Link
          to={`/masters/reels/view/${d.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn">View full data</button>
        </Link>
      </div>
    );
  }
  return <>default</>;
}

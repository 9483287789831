import { modules, perms } from "../../api/codes";
import { items } from "../../data/routes";
import { CategoryChart } from "../../pages/category_chart/category_chart";
import AnnualTurnover from "../../pages/masters/annual_turnover/annual_turnover";
import NewAnnualTurnover from "../../pages/masters/annual_turnover/new_annual_turnover";
import Area from "../../pages/masters/area/area";
import NewArea from "../../pages/masters/area/new_area";
import B2BVerificationFields from "../../pages/masters/b2b_verification_fields/b2b_verification_fields";
import NewB2BVerificationFields from "../../pages/masters/b2b_verification_fields/new_b2b_verification_fields";
import B2BVerificationSections from "../../pages/masters/b2b_verification_sections/b2b_verification_sections";
import NewB2BVerificationSections from "../../pages/masters/b2b_verification_sections/new_b2b_verification_sections";
import Branch from "../../pages/masters/branch/branch";
import NewBranch from "../../pages/masters/branch/new_branch";
import Brand from "../../pages/masters/brand/brand";
import NewBrand from "../../pages/masters/brand/new_brand";
import BusinessType from "../../pages/masters/business_type/business_type";
import NewBusinessType from "../../pages/masters/business_type/new_business_type";
import ChargeName from "../../pages/masters/charge_name/charge_name";
import NewChargeName from "../../pages/masters/charge_name/new_charge_name";
import City from "../../pages/masters/city/city";
import NewCity from "../../pages/masters/city/new_city";
import Color from "../../pages/masters/color/color";
import NewColor from "../../pages/masters/color/new_color";
import Configuration from "../../pages/masters/configuration/configuration";
import NewConfiguration from "../../pages/masters/configuration/new_configuration";
import Continents from "../../pages/masters/continents/continents";
import NewContinents from "../../pages/masters/continents/new_continents";
import Country from "../../pages/masters/country/country";
import NewCountry from "../../pages/masters/country/new_country";
import DeliveryBoyFields from "../../pages/masters/delivery_boy_fields/delivery_boy_fields";
import NewDeliveryBoyFields from "../../pages/masters/delivery_boy_fields/new_delivery_boy_fields";
import DeliveryBoySections from "../../pages/masters/delivery_boy_sections/delivery_boy_sections";
import NewDeliveryBoySections from "../../pages/masters/delivery_boy_sections/new_delivery_boy_sections";
import Department from "../../pages/masters/department/department";
import NewDepartment from "../../pages/masters/department/new_department";
import Designation from "../../pages/masters/designation/designation";
import NewDesignation from "../../pages/masters/designation/new_designation";
import District from "../../pages/masters/district/district";
import NewDistrict from "../../pages/masters/district/new_district";
import FacilityType from "../../pages/masters/facility_type/facility_type";
import NewFacilityType from "../../pages/masters/facility_type/new_facility_type";
import FirstCategory from "../../pages/masters/first_category/first_category";
import NewFirstCategory from "../../pages/masters/first_category/new_first_category";
import Grade from "../../pages/masters/grade/grade";
import NewGrade from "../../pages/masters/grade/new_grade";
import KycCertificate from "../../pages/masters/kyc_certificate/kyc_certificate";
import NewKycCertificate from "../../pages/masters/kyc_certificate/new_kyc_certificate";
import KycDocument from "../../pages/masters/kyc_document/kyc_document";
import NewKycDocument from "../../pages/masters/kyc_document/new_kyc_document";
import LogisticPartner from "../../pages/masters/logistic_partner/logistic_partner";
import NewLogisticPartner from "../../pages/masters/logistic_partner/new_logistic_partner";
import MaritalStatus from "../../pages/masters/marital_status/marital_status";
import NewMaritalStatas from "../../pages/masters/marital_status/new_marital_status";
import Masters from "../../pages/masters/masters";
import NewNotifications from "../../pages/masters/notifications/new_notifications";
import Notifications from "../../pages/masters/notifications/notifications";
import NewOrderStatus from "../../pages/masters/order_status/new_order_status";
import OrderStatus from "../../pages/masters/order_status/order_status";
import NewOrganisationType from "../../pages/masters/organisation_type/new_organisation_type";
import OrganisationType from "../../pages/masters/organisation_type/organisation_type";
import NewPaymentGateway from "../../pages/masters/payment_gateway/new_payment_gateway";
import PaymentGateway from "../../pages/masters/payment_gateway/payment_gateway";
import NewPincode from "../../pages/masters/pincode/new_pincode";
import Pincode from "../../pages/masters/pincode/pincode";
import NewProductInfo from "../../pages/masters/product_info/new_product_info";
import ProductInfo from "../../pages/masters/product_info/product_info";
import NewProductQuestions from "../../pages/masters/product_questions/new_product_questions";
import ProductQuestions from "../../pages/masters/product_questions/product_questions";
import NewProfile from "../../pages/masters/profile/new_profile";
import Profile from "../../pages/masters/profile/profile";
import NewPropertyType from "../../pages/masters/property_type/new_property_type";
import PropertyType from "../../pages/masters/property_type/property_type";
import NewPropertyValue from "../../pages/masters/property_value/new_property_value";
import PropertyValue from "../../pages/masters/property_value/property_value";
import NewReels from "../../pages/masters/reels/new_reels";
import Reels from "../../pages/masters/reels/reels";
import NewRegion from "../../pages/masters/region/new_region";
import Region from "../../pages/masters/region/region";
import NewRoles from "../../pages/masters/roles/new_roles";
import Roles from "../../pages/masters/roles/roles";
import NewSACTaxCode from "../../pages/masters/sac_tax_code/new_sac_tax_code";
import SACTaxCode from "../../pages/masters/sac_tax_code/sac_tax_code";
import NewSACTaxPercent from "../../pages/masters/sac_tax_percentage/new_sac_tax_percentage";
import SACTaxPercent from "../../pages/masters/sac_tax_percentage/sac_tax_percentage";
import NewSecondCategory from "../../pages/masters/second_category/new_second_category";
import SecondCategory from "../../pages/masters/second_category/second_category";
import NewSellerFields from "../../pages/masters/seller_fields/new_seller_fields";
import SellerFields from "../../pages/masters/seller_fields/seller_fields";
import NewSellerSections from "../../pages/masters/seller_sections/new_seller_sections";
import SellerSections from "../../pages/masters/seller_sections/seller_sections";
import NewSellerSubjectCategory from "../../pages/masters/seller_subject_category/new_seller_subject_category";
import SellerSubjectCategory from "../../pages/masters/seller_subject_category/seller_subject_category";
import NewSellerSubjectSubCategory from "../../pages/masters/seller_subject_sub_category/new_seller_subject_sub_category";
import SellerSubjectSubCategory from "../../pages/masters/seller_subject_sub_category/seller_subject_sub_category";
import NewSize from "../../pages/masters/size/new_size";
import Size from "../../pages/masters/size/size";
import NewSizeChartColumn from "../../pages/masters/size_chart_column/new_size_chart_column";
import SizeChartColumn from "../../pages/masters/size_chart_column/size_chart_column";
import NewSortList from "../../pages/masters/sort_list/new_sort_list";
import SortList from "../../pages/masters/sort_list/sort_list";
import NewState from "../../pages/masters/state/new_state";
import State from "../../pages/masters/state/state";
import NewTaluka from "../../pages/masters/taluka/new_taluka";
import Taluka from "../../pages/masters/taluka/taluka";
import NewTaxCode from "../../pages/masters/tax_code/new_tax_code";
import TaxCode from "../../pages/masters/tax_code/tax_code";
import NewTaxPercent from "../../pages/masters/tax_percentage/new_tax_percentage";
import TaxPercent from "../../pages/masters/tax_percentage/tax_percentage";
import NewThirdCategory from "../../pages/masters/third_category/new_third_category";
import ThirdCategory from "../../pages/masters/third_category/third_category";
import NewTitle from "../../pages/masters/title/new_title";
import Title from "../../pages/masters/title/title";
import NewVillage from "../../pages/masters/village/new_village";
import Village from "../../pages/masters/village/village";
import NewWidgetTitle from "../../pages/masters/widget_title/new_widget_title";
import WidgetTitle from "../../pages/masters/widget_title/widget_title";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteMasters = [
  {
    path: "/masters",
    content: (
      <ProtectedElement
        module={items
          .find((item) => item.module === modules.MasterCards)
          .childrens.map((ch) => ch.module)}
        perm={perms.viewList}
        page
      >
        <Masters />
      </ProtectedElement>
    ),
  },
  {
    path: "/masters/category_chart",
    content: (
      <ProtectedElement
        module={modules.CategoryChart}
        perm={perms.viewList}
        page
      >
        <CategoryChart />
      </ProtectedElement>
    ),
  },
  ...makeRoute(
    "order_status",
    OrderStatus,
    NewOrderStatus,
    modules.OrderStatus
  ),
  ...makeRoute("color", Color, NewColor, modules.ProductColor),
  ...makeRoute(
    "first_category",
    FirstCategory,
    NewFirstCategory,
    modules.FirstCategory
  ),
  ...makeRoute(
    "second_category",
    SecondCategory,
    NewSecondCategory,
    modules.SecondCategory
  ),
  ...makeRoute(
    "third_category",
    ThirdCategory,
    NewThirdCategory,
    modules.ThirdCategory
  ),
  // ...makeRoute("ian_type", IANType, NewIANType),
  ...makeRoute(
    "product_questions",
    ProductQuestions,
    NewProductQuestions,
    modules.ProductQuestion
  ),
  ...makeRoute(
    "product_info",
    ProductInfo,
    NewProductInfo,
    modules.ProductInfo
  ),
  ...makeRoute("reels", Reels, NewReels, modules.Reel),
  ...makeRoute("size", Size, NewSize, modules.ProductSize),
  ...makeRoute("sort_list", SortList, NewSortList, modules.SortList),
  ...makeRoute("tax_code", TaxCode, NewTaxCode, modules.HsnCode),
  ...makeRoute("tax_percent", TaxPercent, NewTaxPercent, modules.HsnPercent),
  ...makeRoute("sac_tax_code", SACTaxCode, NewSACTaxCode, modules.SacCode),
  ...makeRoute(
    "sac_tax_percent",
    SACTaxPercent,
    NewSACTaxPercent,
    modules.SacPercent
  ),
  ...makeRoute("luxury_brand", Brand, NewBrand, modules.LuxuryBrand, {
    type: "hot",
  }),
  ...makeRoute("premium_brand", Brand, NewBrand, modules.PremiumBrand, {
    type: "premium",
  }),
  ...makeRoute("charge_name", ChargeName, NewChargeName, modules.ChargeName),
  ...makeRoute(
    "logistics_partner",
    LogisticPartner,
    NewLogisticPartner,
    modules.LogisticPartner
  ),
  ...makeRoute("continents", Continents, NewContinents, modules.Continent),
  ...makeRoute("country", Country, NewCountry, modules.Country),
  ...makeRoute("regions", Region, NewRegion, modules.Region),
  ...makeRoute("pincode", Pincode, NewPincode, modules.Pincode),
  ...makeRoute("city", City, NewCity, modules.City),
  ...makeRoute("district", District, NewDistrict, modules.District),
  ...makeRoute("taluka", Taluka, NewTaluka, modules.Taluka),
  ...makeRoute("village", Village, NewVillage, modules.Village),
  ...makeRoute("state", State, NewState, modules.State),
  ...makeRoute("area", Area, NewArea, modules.Area),
  ...makeRoute(
    "annual_turnover",
    AnnualTurnover,
    NewAnnualTurnover,
    modules.AnnualTurnover
  ),
  ...makeRoute(
    "business_type",
    BusinessType,
    NewBusinessType,
    modules.BusinessType
  ),
  ...makeRoute(
    "organisation_type",
    OrganisationType,
    NewOrganisationType,
    modules.OrganisationType
  ),
  ...makeRoute(
    "facility_type",
    FacilityType,
    NewFacilityType,
    modules.FacilityType
  ),
  ...makeRoute(
    "kyc_document",
    KycDocument,
    NewKycDocument,
    modules.DocumentType
  ),
  ...makeRoute(
    "kyc_certificate",
    KycCertificate,
    NewKycCertificate,
    modules.CertificateType
  ),
  // ...makeRoute("pls_kyc_document", PLSDocument, NewPLSDocument),
  // ...makeRoute("pls_kyc_certificate", PLSCertificate, NewPLSCertificate),
  ...makeRoute(
    "size_chart_column",
    SizeChartColumn,
    NewSizeChartColumn,
    modules.SizeChartColumn
  ),
  ...makeRoute(
    "marital_status",
    MaritalStatus,
    NewMaritalStatas,
    modules.MartialStatus
  ),
  ...makeRoute("title", Title, NewTitle, modules.Title),
  // ...makeRoute("filter_category", FilterCategory, NewFilterCategory, modules.fi),
  ...makeRoute("roles", Roles, NewRoles, modules.Role),
  ...makeRoute(
    "property_type",
    PropertyType,
    NewPropertyType,
    modules.PropertyType
  ),
  ...makeRoute(
    "property_value",
    PropertyValue,
    NewPropertyValue,
    modules.PropertyValue
  ),
  ...makeRoute(
    "payment_gateway",
    PaymentGateway,
    NewPaymentGateway,
    modules.PaymentGateway
  ),
  ...makeRoute(
    "widget_title",
    WidgetTitle,
    NewWidgetTitle,
    modules.WidgetTitle
  ),
  ...makeRoute(
    "configuration",
    Configuration,
    NewConfiguration,
    modules.Configuration
  ),
  ...makeRoute(
    "notifications",
    Notifications,
    NewNotifications,
    modules.Notification
  ),
  ...makeRoute("branch", Branch, NewBranch, modules.Branch),
  ...makeRoute("department", Department, NewDepartment, modules.Department),
  ...makeRoute("designation", Designation, NewDesignation, modules.Designation),
  ...makeRoute("grade", Grade, NewGrade, modules.Granding),
  ...makeRoute("profile", Profile, NewProfile, modules.Profile),
  ...makeRoute(
    "b2b_verification_fields",
    B2BVerificationFields,
    NewB2BVerificationFields,
    modules.B2BVerificationFeilds
  ),
  ...makeRoute(
    "b2b_verification_sections",
    B2BVerificationSections,
    NewB2BVerificationSections,
    modules.B2BVerificationSections
  ),
  ...makeRoute(
    "delivery_boy_sections",
    DeliveryBoySections,
    NewDeliveryBoySections,
    modules.DeliveryBoySections
  ),
  ...makeRoute(
    "delivery_boy_fields",
    DeliveryBoyFields,
    NewDeliveryBoyFields,
    modules.DeliveryBoyFeilds
  ),
  ...makeRoute(
    "seller_subject_category",
    SellerSubjectCategory,
    NewSellerSubjectCategory,
    modules.SellerSubjectCategory
  ),
  ...makeRoute(
    "seller_subject_sub_category",
    SellerSubjectSubCategory,
    NewSellerSubjectSubCategory,
    modules.SellerSubjectSubCategory
  ),
  ...makeRoute(
    "seller_fields",
    SellerFields,
    NewSellerFields,
    modules.SellerFeilds
  ),
  ...makeRoute(
    "seller_sections",
    SellerSections,
    NewSellerSections,
    modules.SellerSections
  ),
];

function makeRoute(route, Dashboard, Form, module, props = {}) {
  // if (readOnly) {
  //   return [
  //     {
  //       path: `/masters/${route}`,
  //       content: (
  //         <ProtectedElement module={module} perm={perms.viewList} page>
  //           <Dashboard isGlobal={false} />
  //         </ProtectedElement>
  //       ),
  //     },
  //   ];
  // }
  return [
    {
      path: `/masters/${route}`,
      content: (
        <ProtectedElement module={module} perm={perms.viewList} page>
          <Dashboard isGlobal={false} {...props} />
        </ProtectedElement>
      ),
    },
    {
      path: `/masters/${route}/new`,
      content: (
        <ProtectedElement module={module} perm={perms.add} page>
          <Form isGlobal={false} {...props} />
        </ProtectedElement>
      ),
    },
    {
      path: `/masters/${route}/edit/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.edit} page>
          <Form edit isGlobal={false} {...props} />
        </ProtectedElement>
      ),
    },
    {
      path: `/masters/${route}/view/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.view} page>
          <Form view isGlobal={false} {...props} />
        </ProtectedElement>
      ),
    },
    {
      path: `/masters/${route}/copy/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.add} page>
          <Form isGlobal={false} {...props} />
        </ProtectedElement>
      ),
    },
  ];
}
